import isMobilePhone from 'validator/lib/isMobilePhone'

export default {
    required: (field, message) => ({
        value: true,
        message: message || `You must enter ${field}.`
    }),
    phone: (field, message) => value => {
        return isMobilePhone(value, 'en-US') || (message || `You must enter valid ${field}`)
    },
    date: (field, message) => ({
        value: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20|21)\d{2}$/,
        message: message || `You must enter valid ${field}.`
    }),
    positive: (field, message) => ({
        value: /^[$]?[1-9]{1,3}(?:,?[0-9]{1,3})*(?:\.[0-9]{1,2})?$/,
        message: message || `${field} should be great than 0.`
    }),
    min: (min, field, message) => value => {
        return value > min || (message || `${field} must be more than ${min}`)
    },
    minAmount: (min, field, message) => value => {
        return value.replace(/[$,]/g, '') >= min || (message || `${field} must be more than $${min}`)
    },
    email: (field, message) => ({
        value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
        message: message || `You must enter valid ${field}.`
    })
}
