import fetch from './fetch'

export default {
    index () {
        return fetch.get(`/api/notifications`)
    },
    update (payload) {
        return fetch.post(`/api/notifications/update`, payload)
    }
}
