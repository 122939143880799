export const gender = [
    'Girl',
    'Boy',
    // 'Prefer not to say',
    "It's a surprise"

]

export const experience = [
    'First-time',
    'Experienced'
]

export const contributors = [
    { value: 'guest', title: 'Contributors' },
    { value: 'account', title: 'You' },
]

export const countries = [
    { value: 'US', title: 'United States of America' },
]

export const states = [
    'California',
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
]

export const onboarding = [
    { id: 1, value: 'Infant Care' },
    { id: 2, value: 'Fitness' },
    { id: 3, value: 'Holistic Medicine' },
    { id: 4, value: 'Herbal Remedies' },
    { id: 5, value: 'Meal Planning' },
    { id: 6, value: 'Organic Products' },
    { id: 7, value: 'Sustainability' },
    { id: 8, value: 'Wellness Services' },
    { id: 9, value: 'Nursery Planning' },
    { id: 10, value: 'Parenting' },
    { id: 11, value: 'Minimalist Living' },
    { id: 12, value: 'Meditation' },
    { id: 13, value: 'Maternal Wellness' },
    { id: 14, value: 'Family Support' },
    { id: 15, value: 'Community' },
    { id: 16, value: 'Childcare' },
    { id: 17, value: 'Family activities' },
    { id: 18, value: 'Infant feeding' },
    { id: 19, value: 'Clean Skincare' },
    { id: 20, value: 'Self Care' },
]


export const hearList = [
    'Friend or Family',
    'Google',
    'Instagram',
    'Facebook',
    'Podcast',
    'Digital Media',
    'Other'
]