import fetch from './fetch'
import { getState } from '../../store'

export default {
    async getLinkToken () {
        const { user } = getState()
        const { link_token } = await fetch.post('/api/create-link-token', { user }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return link_token
    }
}
