import fetch from './fetch'

export default {
    getAll() {
        return fetch.get('https://journal.littlehoneymoney.com/wp-json')
        .catch(e => console.log(e))
    },
    getAllPosts() {
        return fetch.get('https://journal.littlehoneymoney.com/wp-json/wp/v2/posts?_embed')
        .catch(e => console.log(e))
    },
    async getAllBlogs() {
        let exist = true;
        let records = [];
        let offset = 0;
        let page = 1;

        do {
            let response = await fetch.get(`https://journal.littlehoneymoney.com/wp-json/wp/v2/posts?per_page=10&page=${page}&offset=${offset}&_embed`)
            .catch(e => console.log(e))
            exist = response && response.length === 10;
            if (exist) {
                offset +=10;
                page++;
            }
            records = [...records, ...response];
        } while (exist);

        return records;
    },
    getAllCategories() {
        return fetch.get('https://journal.littlehoneymoney.com/wp-json/wp/v2/categories')
        .catch(e => console.log(e))
    },
    getBlog(slug) {
        return fetch.get(`https://journal.littlehoneymoney.com/wp-json/wp/v2/posts?slug=${slug}`)
        .catch(e => console.log(e))
    },
    getAcf(id) {
        return fetch.get(`https://journal.littlehoneymoney.com/wp-json/wp/v2/posts/${id}?_fields=acf&acf_format=standard`)
        .catch(e => console.log(e))
    },
    getRecommendation(category) {
        return  fetch.get(`https://journal.littlehoneymoney.com/wp-json/wp/v2/posts?categories=${category}`)
        .catch(e => console.log(e))
    },
    getMedia(id) {
        return  fetch.get(`https://journal.littlehoneymoney.com/wp-json/wp/v2/media/${id}`)
        .catch(e => console.log(e))
    }
}
