import auth from './auth'
import api from './api'
import format from './format'
import fees from './fees'
import rules from './rules'
import s3 from './s3'
import ga from './ga'
import { basePath } from '../utils'

export default {
    auth,
    api,
    format,
    fees,
    rules,
    s3,
    ga,
    getRegistryUrl: (url) => {
        return basePath + '/registry/' + url
    }
}
