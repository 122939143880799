import Storage from '@aws-amplify/storage'
import { v4 as uuidv4 } from 'uuid'
import config from '../src/aws-exports'

export default {
    get: async (name) => {
        try {
            if (name) {
                const blob = await Storage.get(name, { download: true })
                return blob.Body.size > 0 ? URL.createObjectURL(blob.Body) : null
            } else {
                return null
            }
        } catch (e) {
            return null
        }
    },

    put: async file => {
        const [extension] = /[^.]+$/.exec(file.name)
        const { key } = await Storage.put(`${uuidv4()}.${extension}`, file, {
            contentType: file.type,
        })
        const url = `https://${config.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${key}`
        return { key, url }
    },

    delete: (name) => {
        return Storage.remove(name)
    }
}
