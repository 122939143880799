import { API } from 'aws-amplify'
import auth from '../auth'
import errorHandler from './errorHandler'
import { getState } from '../../store'

const config = async () => {
    const cognitoUserSession = await auth.session()
    return { headers: { Authorization: cognitoUserSession.idToken.jwtToken } }
}

export default {
    getByUserId (userId) {
        return API.get('Registry', `/user/${userId}`)
    },
    async getByUserIdWithUser (userId) {
        const { data } = await API.get('Registry', `/user/with-user/${userId}`)
        return data
    },
    getByUrl (url) {
        return API.get('Registry', `/${url}`)
    },
    getByUrlWithUser (url) {
        return API.get('Registry', `/with-user/${url}`)
    },
    getAll (params) {
        return API.get('Registry', ``, {
            queryStringParameters: params
        })
    },
    async getMy () {
        const user = await auth.currAuthUser()
        const { data } = await API.get('Registry', `/user/${user.username}`)
        return data
    },
    async store (body) {
        if (body.hasOwnProperty('registryUrl')) {
            body.registryUrl = body.registryUrl
                .split('-')
                .map(word => word.replace(/[^a-zA-Z]/g, ''))
                .join('-');
        }

        try {
            const configObj = await config()
            return await API.post('Registry', '', { body, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    async update (body) {
        if (body.hasOwnProperty('registryUrl')) {
            body.registryUrl = body.registryUrl
                .split('-')
                .map(word => word.replace(/[^a-zA-Z]/g, ''))
                .join('-');
        }

        try {
            const registry = await this.getMy()
            const configObj = await config()
            const res = await API.put('Registry', `/update/${registry.registryId}`, { body, ...configObj })
            fetch(`/registry/${res.data.registryUrl}`)
            return res
        } catch (e) {
            errorHandler(e)
        }
    }
}
