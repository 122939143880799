const CC_PERCENT_FEE = 2.9
const CC_AMOUNT_FEE = .3

const THIRD_PARTY_PERCENT_FEE = 0
const THIRD_PARTY_AMOUNT_FEE = .25

const LHM_PERCENT_FEE = 5
const LHM_AMOUNT_FEE = .95

export default {
    amountFee: CC_AMOUNT_FEE + THIRD_PARTY_AMOUNT_FEE,
    percentFee: CC_PERCENT_FEE + THIRD_PARTY_PERCENT_FEE + LHM_PERCENT_FEE,
    ccPercentFee: CC_PERCENT_FEE,
    ccAmountFee: CC_AMOUNT_FEE,
    thirdPartyPercentFee: THIRD_PARTY_PERCENT_FEE,
    thirdPartyAmountFee: THIRD_PARTY_AMOUNT_FEE,
    lhmPercentFee: LHM_PERCENT_FEE,
    lhmAmountFee: LHM_AMOUNT_FEE,
    calculateFee (amount) {
        return amount * this.percentFee / 100 + this.amountFee
    }
}
