import { API } from 'aws-amplify'
import auth from '../auth'
import { getState } from '../../store'
import errorHandler from './errorHandler'

const config = async () => {
    const cognitoUserSession = await auth.session()
    return { headers: { Authorization: cognitoUserSession.idToken.jwtToken } }
}

export default {
    store: async queryData => {
        try {
            const configObj = await config()
            return await API.post('Funds', '/', { body: queryData, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    update: async (fundId, queryData) => {
        try {
            const configObj = await config()
            return await API.put('Funds', `/update/${fundId}`, { body: queryData, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    delete: async fundId => {
        try {
            const configObj = await config()
            const { registry } = getState()
            return await API.del('Funds', `/delete/${fundId}`, { queryStringParameters: { registryId: registry.registryId }, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    getByRegistryId: registryId => {
        return API.get('Funds', `/registry_id/${registryId}`)
    }
}

