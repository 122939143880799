import img10 from '../assets/images/img10.jpg'

export const funds = [
    {
        id: '14b820cd-c350-42d2-8538-07fb78a367d2',
        name: 'Acupuncture and Acupressure',
        text: 'Rooted in Traditional Chinese Medicine, acupuncture and acupressure mobilize the body’s life energy for healing and wellness. These holistic therapies are known to help ease a number of common perinatal pains, stresses, and discomforts—and even improve fertility outcomes. Many women look to these therapies during their perinatal years for regular health maintenance in addition to treatment of common issues. Pediatric acupressure may also be sought to help ease newborn discomforts.',
        categories: ['Planning', 'Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: "https://little-honey-money.imgix.net/registry/scott-webb.png"
    },
    {
        id: '8d003770-afd1-4def-a3f2-99294be8ddc9',
        name: 'Adoption',
        text: 'Through adoption, new parents may embark on parenthood in a modern way. The process can be exciting yet costly—but when loved ones can offer the gift of their financial support, they, too, can play a meaningful part in the new parents’ journey to parenthood.',
        categories: ['Planning'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/pexels-william-fortunato-6393333%201@2x.png'
    },
    {
        id: '7489360d-6c57-41cb-b28b-23194c4869e4',
        name: 'Birth Preparation and Parenting Classes',
        text: 'With the right knowledge and support, a new mom can handle anything. In-person and virtual childbirth and parenting classes provide education and support around trying to conceive, labor and delivery, preparing for postpartum, caring for a newborn, and more—including the CPR and safety training that are so essential to infant care.',
        categories: ['Planning', 'Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/kelly-sikkema.png'
    },
    {
        id: '20be24ce-5809-4cc0-b222-696b2a53e6dd',
        name: 'Childcare',
        text: 'Parenting was never meant to be done alone. Babysitters, nannies, mother’s helpers, and daycare provide additional care, support, and safety for families. Parents juggle a lot these days—access to quality childcare is often what makes it all possible.',
        categories: ['Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/charlesdeluvio-2vfwTakDTIo-unsplash%201@2x.png'
    },
    {
        id: 'f243ac71-3d28-40b1-8988-b86d9dd412c8',
        name: 'Chiropractic Care',
        text: 'Chiropractic healthcare providers specialize in the body’s neuromusculoskeletal system—bones, muscles, tendons, ligaments, and nerves. As the maternal body goes through significant changes, chiropractic care works to heal the body holistically, in harmony with itself. In addition to correcting postural and alignment issues, chiropractic care can help prepare one’s body for conception, and ease pain during pregnancy, postpartum, and beyond.',
        categories: ['Planning', 'Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/ivan-zakharenko-KeGKEOjhy6E-unsplash%201@2x.png'
    },
    {
        id: '3bccaaab-70cd-4114-ad63-689b3aacfd2a',
        name: 'Coaching',
        text: 'It always helps to have someone in your corner. Coaches guide curious, expectant, and new parents through the transformative and often misunderstood experiences of planning conception to matrescence and beyond. Parenthood brings a major shift, and with it comes brand new experiences at every turn—but with coaching along the way, so much of it can come as second nature, giving parents the confidence to make decisions for their growing family.',
        categories: ['Planning', 'Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/artem-kovalev.png'
    },
    {
        id: 'dfa791d9-6617-49d4-827d-c025f56c388f',
        name: 'Doula',
        text: 'Many women choose to have a doula present to coach and advocate for them during at-home and hospital births. Doulas are trained professionals who provide continuous physical, emotional, and informational support to a mother before, during and, after childbirth. Doulas provide invaluable comfort to all family members, in addition to the mother. Research has shown that having a doula’s support during—and leading up to—childbirth can result in better outcomes for both mom and baby.',
        categories: ['Planning', 'Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/priscilla-du-preez.png'
    },
    {
        id: 'f7fea75c-1be3-408e-87ed-13290d598a0f',
        name: 'Fertility Treatment and Counseling',
        text: 'Through fertility treatments like In Vitro Fertilization (IVF), new parents may embark on parenthood in a modern way. Though it can be complicated, there are a number of support services available to help new parents find happiness and success through it all. The process can be exciting, emotional, and incredibly costly—but when loved ones can offer the gift of their financial support, they, too, can play a meaningful part in the new parents’ journey to parenthood.',
        categories: ['Planning'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/taisiia-shestopal-aIy.png'
    },
    {
        id: '6bb7b3c3-f028-4aae-8a66-c670e56ddca0',
        name: 'Fitness Classes',
        text: 'Athletic trainers educate, support, and train women throughout their reproductive journeys—and group classes make it fun. With support, women can safely continue their exercise regimens during fertility treatments,  pregnancy, postpartum, and set healthy, realistic expectations around movement.',
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/jade-stephens-N21356amsyw-unsplash%201@2x.png'
    },
    {
        id: '7806299c-d57d-4a52-b15e-d9ed254d0321',
        name: 'Housekeeper',
        text: 'An important investment for many new parents, cleaning professionals take over household chores like laundry, dishes, changing sheets, and tidying up, so that the new family can rest, heal, bond, and cherish any downtime together.',
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/thom-bradley-7FE2UTxR1Yw-unsplash%201@2x.png'
    },
    {
        id: 'ab8c9346-45b8-402b-bdc7-c9ed16b29cf8',
        name: 'Lactation Consultant',
        text: 'International Board Certified Lactation Consultants (IBCLCs) and Certified Lactation Consultants (CLCs) are trained professionals who support women through their breastfeeding journeys. Lactation consultants provide education around breastfeeding, help babies establish a deep latch, and troubleshoot issues that arise. With the additional support, improved outcomes can be experienced by mother and baby.',
        categories: ['Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/jasmine-huang.png'
    },
    {
        id: '256ecd14-5b66-428f-8f0c-e08a97dd0f41',
        name: 'Massage',
        text: 'No one deserves a massage more than an expectant or new mom. Fertility, prenatal and postpartum bodywork has a number of benefits including relaxation, stress and pain relief, reduced swelling, increased blood flow, immunity, and better sleep. Sign a mama up.',
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/han-chenxu-tu_mv6p2p5U-unsplash%201@2x.png'
    },
    {
        id: '3180a794-fdc7-4029-9b26-884a444b7a2b',
        name: 'Meal Deliveries',
        text: 'Receiving the gift of nutritious food is one of the best ways for new parents to receive support. Meal delivery services take cooking off the new family’s plate, while ensuring they receive wholesome, delicious, ready-made meals.',
        categories: ['Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/bruna-branco-t8hTmte4O_g-unsplash%201@2x.png'
    },
    {
        id: 'efb7d97e-b503-45ca-b50c-2920877e85df',
        name: 'Midwife',
        text: 'Alternative birth professionals to OB-GYNs, midwives have long since delivered babies all over the world. Providing holistic support and medical care throughout pregnancy, childbirth, and the postpartum period, a woman may choose to have her baby (or, babies) delivered by a midwife. Midwives are most often sought in at-home births and in birthing centers, and serve as a crucial member of the maternal care team.',
        categories: ['Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/pexels-cottonbro-5853200%201@2x.png'
    },
    {
        id: 'e6e0d9e4-a437-493b-ab27-f7c5eaed6228',
        name: 'Newborn Care Specialist',
        text: 'Newborn Care Specialists (NCSs) help new parents learn new tasks like swaddling, breastfeeding, infant sleep, bathing, and other aspects of newborn care. Through specialized training and caring support, they provide education and an extra set of hands during the transition to parenthood. Many new families utilize NCSs in the overnight hours, so the new parents can rest assured their baby is looked after while they get much-needed sleep.',
        categories: ['Pregnancy', 'Postpartum', 'Baby'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/kelly-sikkema-Jqhwp4mcuUM-unsplash%201@2x.png'
    },
    {
        id: 'fad67607-82d1-4947-948f-b9bb9ac4cf5e',
        name: 'Nutrition Counseling',
        text: 'Whether it’s choosing a prenatal vitamin, ensuring you’re taking in enough iron, or keeping up with the caloric demands of breastfeeding, a mother’s food intake is an important part of her wellness journey. A registered dietitian (R.D.) provides advice, support, and guidance around nutrition as needs change.',
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/brooke-lark-aGjP08-HbYY-unsplash%201@2x.png'
    },
    {
        id: '4d5be94b-2d7b-48a3-ae70-ac9c4a00f8ba',
        name: 'Pelvic Floor Physical Therapy',
        text: 'Pelvic floor physical therapy is important during the postpartum period as it involves the strengthening and rehabilitation of muscles that support the pelvic organs helps prepare for and recover from childbirth. This work can also involve rehabilitating the core muscles and recovery from a vaginal or cesarean delivery.',
        categories: ['Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/apostolos-vamvouras-teuTE1VyajU-unsplash%201@2x.png'
    },
    {
        id: '45399b15-5ab4-4dd2-9258-32fbe76f72bf',
        name: 'Perinatal Mental Health Services',
        text: 'Mental health professionals who specialize in perinatal mood and anxiety disorders (PMADs), such as postpartum anxiety and postpartum depression, help mothers navigate a variety of common and treatable mental health conditions that can occur throughout the reproductive journey. Many women choose lean on mental health providers for support, even when they are considered at low risk of developing PMADs.',
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/lotte-de-jong.png'
    },
    {
        id: '42c936eb-0e5d-414d-aae1-a5c708d63434',
        name: 'Support Groups',
        text: 'Community makes motherhood much more fun. Virtual and in-person meetings with other new parents can provide much needed social support, education, and emotional healing throughout the different stages of matrescence. Mom groups are a communal way to connect with other parents, bond with baby, and learn from the experts—some moms even make lifelong friendships that began in their very first baby group.',
        categories: ['Pregnancy', 'Postpartum'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/pexels-rfstudio-3810832%201@2x.png'
    },
    {
        id: 'df95b9af-97a4-4351-8c20-27e3b65a7772',
        name: 'Surrogate',
        text: 'Through surrogacy, new parents may embark on parenthood in a modern way. The process can be exciting yet costly—but when loved ones can offer the gift of their financial support, they, too, can play a meaningful part in the new parents’ journey to parenthood.',
        categories: ['Planning'],
        type: 'fund',
        image: 'https://little-honey-money.imgix.net/registry/1460095%201@2x.png'
    },
]

export const serviceItems = [
    {
        id: '71fa22a0-74ee-446a-be80-c0e7188c8ab6',
        name: 'Acupuncture Session',
        text: 'Rooted in Traditional Chinese Medicine, acupuncture and acupressure mobilize the body’s life energy for healing and wellness. This holistic therapy is known to help ease a number of common perinatal pains, stresses, and discomforts—and even improve fertility outcomes. Many women look to these therapies during their perinatal years for regular health maintenance in addition to treatment of common issues.',
        cost: 125,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/taisiia-shestopal-6zgnh2f7DQw-unsplash%201@2x.png'
    },
    {
        id: '94573fde-2d9e-495c-a2ec-84ae949bfbb5',
        name: 'Babysitter - Date Night',
        text: 'Parenting was never meant to be done alone. Babysitters, nannies, mother’s helpers, and daycare provide additional care, support, and safety for families. Parents juggle a lot these days—access to quality childcare is often what makes it all possible.',
        cost: 80,
        categories: ['Pregnancy', 'Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-kristina-paukshtite-3242264.jpg'
    },
    {
        id: '58d21450-4d00-4374-a332-209face244e7',
        name: 'Babysitter - Sibling Special Time',
        text: 'Parenting was never meant to be done alone. Babysitters, nannies, mother’s helpers, and daycare provide additional care, support, and safety for families. Parents juggle a lot these days—access to quality childcare is often what makes it all possible.',
        cost: 80,
        categories: ['Pregnancy', 'Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/1197535%201@2x.png'
    },
    {
        id: 'fb44d1cb-fd09-4b04-be08-bd518c65b721',
        name: 'Baby Food Delivery - Meals for One Month',
        text: 'Baby food delivery services make it easy for new parents to feed their baby healthy and nutritious meals. From baby purees to toddler finger foods, parents can receive easy-prep meals made of high-quality, fresh foods right to their doorstep.',
        cost: 300,
        categories: ['Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/joanna-kosinska-QeKCqh6TOQI-unsplash%201@2x.png'
    },
    {
        id: '7fb3a0f0-bc84-4494-b0a7-b8766b05c165',
        name: 'Baby Food Delivery - Meals for One Week',
        text: 'Baby food delivery services make it easy for new parents to feed their baby healthy and nutritious meals. From baby purees to toddler finger foods, parents can receive easy-prep meals made of high-quality, fresh foods right to their doorstep.',
        cost: 125,
        categories: ['Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-element-digital-775031%201@2x.png'
    },
    {
        id: '1b03fcbf-9b6f-4a12-918c-b2df40a59f7d',
        name: 'Baby Nurse - One Overnight Session',
        text: 'Baby nurses help new parents understand their new baby\'s needs and how to care for them. They teach new tasks like swaddling, breastfeeding, infant sleep, bathing, and other aspects of newborn care. Through specialized training and caring support, they provide education and an extra set of hands during the transition to parenthood. Many new families utilize aby nurses in the overnight hours, so the new parents can rest assured their baby is looked after while they get much-needed sleep.',
        cost: 300,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/2309333%201@2x.png'
    },
    {
        id: 'a70c1e62-1229-4adb-883d-b478194cd8d3',
        name: 'Baby Nurse - One Week Overnight Sessions',
        text: 'Baby nurses help new parents understand their new baby\'s needs and how to care for them. They teach new tasks like swaddling, breastfeeding, infant sleep, bathing, and other aspects of newborn care. Through specialized training and caring support, they provide education and an extra set of hands during the transition to parenthood. Many new families utilize aby nurses in the overnight hours, so the new parents can rest assured their baby is looked after while they get much-needed sleep.',
        cost: 2000,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-pixabay-161709%201@2x.png'
    },
    {
        id: '6a1fda41-09e5-40ed-a046-44ce09ef21c6',
        name: 'Belly Binding Ritual',
        text: 'Belly Binding after birth is an ancient ritual practiced around the world, that includes long strips of cloth being wound firmly around a woman’s midsection after giving birth. This sacred ritual is performed in ceremony and offers physical benefits to the woman’s healing postpartum body including closing of the abdomen, swelling reduction and tightening of stretched muscles.',
        cost: 200,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-cottonbro-5852460%201@2x.png'
    },
    {
        id: 'eb59e916-af01-4fc5-98f6-5a56b12c1c76',
        name: 'Birth Preparation Class',
        text: 'In-person and virtual childbirth classes provide education and support around what to expect during labor and techniques that can be used as comfort measures.',
        cost: 200,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/jonathan-borba.png'
    },
    {
        id: '849008b3-70f6-4a16-ae1e-5487e127448d',
        name: 'Breast Milk Transportation Service',
        text: 'A new mom may find the need to efficiently ship breast milk for her baby. Whether transporting a milk supply from a surrogate, or shipping home a working mom\'s pump supply,  transportation services exist to safely move breast milk locally or nationally.',
        cost: 200,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/728380@2x.png'
    },
    {
        id: 'd3450fcc-9323-4b3d-99ac-7542353ababd',
        name: 'Chiropractic Care Session',
        text: 'Chiropractic healthcare providers specialize in the body’s neuromusculoskeletal system—bones, muscles, tendons, ligaments, and nerves. As the maternal body goes through significant changes, chiropractic care works to heal the body holistically, in harmony with itself. In addition to correcting postural and alignment issues, chiropractic care can help prepare one’s body for conception, and ease pain during pregnancy, postpartum, and beyond.',
        cost: 185,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-andrea-piacquadio-3881012%201@2x.png'
    },
    {
        id: '21014749-8b45-4592-ac59-6cc8c860800f',
        name: 'Closing of the Bones Ceremony',
        text: 'The Closing of the Bones ceremony is a deeply nurturing treatment that acknowledges the immense changes a woman has undergone in pregnancy and childbirth and assists in bringing her spirit back into her own body. Physically, it guides her bones back into place, helps her pelvic organs shift and her uterus to shrink back down, and stimulates blood flow. More than just physical work, many believe in its deeply energetic work as a rite of passage.',
        cost: 200,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/chandra-oh.png'
    },
    {
        id: '7b7e2262-b3af-4289-b398-8c1081334b9b',
        name: 'Cord Blood Banking Service - One Year',
        text: 'Cord blood banking is a procedure in which blood is taken from the umbilical cord immediately after a baby is born. Because cord blood is a rich source of stem cells, some families choose to store a sample of their baby\'s cord blood for future health-related use.',
        cost: 2500,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-karolina-grabowska-4021793%201@2x.png'
    },
    {
        id: 'b5ab6cf7-4559-43a5-9a91-62f05cb13e56',
        name: 'CPR Training Class',
        text: 'Infant CPR training classes teach new parents the basics of how to recognize and respond to medical emergencies, provide a range of first-aid strategies and lifesaving procedures, and perform rescue breathing and chest compressions for babies.',
        cost: 75,
        categories: ['Pregnancy', 'Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/ana-tablas-oB0xbLwcaMw-unsplash%201@2x.png'
    },
    {
        id: '26cf25e2-69b2-4e2f-b31d-39dc8f9dac64',
        name: 'Cranial Sacral Therapy Session',
        text: 'Cranial Sacral therapy is noninvasive bodywork that relieves compression in the bones of the head, sacrum, and spinal column. It uses gentle pressure on the head, neck, and back to relieve the stress and pain and is known for calming the nervous system in new and expectant mothers and infants. Cranial Sacral therapy can also help identify the source of common infant discomforts and issues.',
        cost: 250,
        categories: ['Planning', 'Pregnancy', 'Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp9e101f12tRQ300_Medium_32232%201@2x.png'
    },
    {
        id: '95e6da0e-ca26-45ba-8f1e-761ef224d140',
        name: 'Cupping Session',
        text: 'Cupping is a holistic therapy sought by women before and after pregnancy, though typically not during. When trying to conceive, cupping can release stress and improve the environment surrounding the fallopian tubes, increasing fertility. Cupping can also be a valuable therapy during postpartum recovery as it helps relax muscles, increase blood flow, and remove stagnant energy in the body.',
        cost: 80,
        categories: ['Planning', 'Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp9e101f12tRQ300_Medium_4570078%201@2x.png'
    },
    {
        id: '0b2a9bf3-e8f7-4a0b-a9c0-05a31668ff79',
        name: 'Daycare - One Week',
        text: 'Parenting was never meant to be done alone. Babysitters, nannies, mother’s helpers, and daycare provide additional care, support, and safety for families. Parents juggle a lot these days—access to quality childcare is often what makes it all possible.',
        cost: 200,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/toa-heftiba-Ws_z3HTP_Yo-unsplash%201@2x.png'
    },
    {
        id: '56980289-bbb6-431e-8fc5-f5ac79e80d4a',
        name: 'Dog Walking Service',
        text: 'Dog walking services help new parents make sure the fur babies get plenty of love while they\'re adjusting to life with a new baby.',
        cost: 35,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp273a1e32QNQ300_Medium_1885285%201@2x.png'
    },
    {
        id: '0d69b723-f3ea-46cb-b4eb-62afa0c18e2c',
        name: 'Fertility Counseling',
        text: 'There are many different paths to parenthood and no two journeys are alike. Fertility counseling is a form of therapy designed to support individuals or couples who want to conceive but need some extra guideance planning their conception journey or working through current struggles. Fertility counselors can also provide education for those considering using a sperm or egg donor or thinking about adoption or surrogacy.',
        cost: 250,
        categories: ['Planning'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/847653.png'
    },
    {
        id: 'dd4f0443-f043-4a84-abfe-894141040d02',
        name: 'Gua Sha',
        text: 'Gua sha is a traditional Chinese healing method that uses smooth-edged stone tool to stroke skin while pressing firmly to relieve pressure. It is believed to increase blood flow, release toxins, and promote healing, amongst other uses. A mom-to-be can even enjoy a pregnancy-safe gua sha facial for extra pampering with its extra lifting effect. Gua sha can also be used to relieve clogged milk ducts during breastfeeding.',
        cost: 75,
        categories: ['Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/content-pixie-7K4mlDH6wZo-unsplash%201@2x.png'
    },
    {
        id: '6dbb5ad6-4b7d-4f53-8f69-026458e00768',
        name: 'Guided Meditation Session',
        text: 'Meditation can be helpful during all perinatal phases — from planning conception, to pregnancy, postpartum and beyond. A wonderful way to relax the mind and find peace throughout the journey, creating a calm enviroment for both mom and baby.',
        cost: 40,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-pnw-production-8981313%201@2x.png'
    },
    {
        id: 'e93bb043-abba-42bd-8919-9a3b3b72c2aa',
        name: 'Housekeeping Service',
        text: 'An important investment for many new parents, cleaning professionals take over household chores like laundry, dishes, changing sheets, and tidying up, so that the new family can rest, heal, bond, and cherish any downtime together.',
        cost: 125,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/daiga-ellaby-699140-unsplash%201@2x.png'
    },
    {
        id: 'e862754b-8001-4e6c-b7c1-dfb3944b8145',
        name: 'Hypnobirthing Coach',
        text: 'Hypnobirthing is a natural comfort measure used during labor and birth that involves visualization, self-hypnosis, deep relaxation, and deep breathing techniques. Hypnobirthing coaches teach the techniques, and once they are learned, they can be used to help a new mom focus and concentrate completely on her body and baby for natural pain-relieving effect during childbirth.',
        cost: 500,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/2840370.png'
    },
    {
        id: '0d9efcce-a0cc-4abc-900b-314f704c0f16',
        name: 'Hypnobirthing Group Class',
        text: 'Hypnobirthing is a natural comfort measure used during labor and birth that involves visualization, self-hypnosis, deep relaxation, and deep breathing techniques. Hypnobirthing coaches teach the techniques, and once they are learned, they can be used to help a new mom focus and concentrate completely on her body and baby for natural pain-relieving effect during childbirth.',
        cost: 300,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/two_girls.png'
    },
    {
        id: '0abc60bc-28ab-4bbb-b758-d0da1b761a8f',
        name: 'Infant Acupressure Session',
        text: 'Rooted in Traditional Chinese Medicine, acupuncture and acupressure mobilize the body’s life energy for healing and wellness. Pediatric acupressure is a holistic remedy aimed to help ease common newborn discomforts.',
        cost: 125,
        categories: ['Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/373185%201@2x.png'
    },
    {
        id: 'a11d18a6-3351-4e7c-b320-84f9f8d63e11',
        name: 'Lactation Coaching Series',
        text: 'Every mother\'s breastfeeding experience is unique. Lactation coaches provide ongoing support for breastfeeding mothers to maintain optimal milk supply levels and help troubleshoot any issues they encounter.',
        cost: 300,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/rawpixel-600784-unsplash%202@2x.png'
    },
    {
        id: '34afc0b4-4368-4ed2-a484-3f2f35304924',
        name: 'Lactation Consultant',
        text: 'International Board Certified Lactation Consultants (IBCLCs) and Certified Lactation Consultants (CLCs) are trained professionals who support women through their breastfeeding journeys. Lactation consultants provide education around breastfeeding, help babies establish a deep latch, and troubleshoot issues that arise. With the additional support, improved outcomes can be experienced by mother and baby.',
        cost: 100,
        categories: ['Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/tuyen-vo.png'
    },
    {
        id: 'fef4e4a9-6e81-40a2-a054-b0bca6b77a23',
        name: 'Lymphatic Drainage',
        text: 'Lymphatic drainage is a gentle massage of the lymphatic system that has a number of benefits, including detoxification of the body and balancing hormones. This massage is known to be beneficial for all perinatal phases, starting from when trying to conceive all the way through new motherhood.',
        cost: 185,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/thanos-pal.png'
    },
    {
        id: '54c4c34d-96a3-42e4-a5e5-cefe8027bbda',
        name: 'Meal Delivery for the New Mom',
        text: 'Receiving the gift of nutritious food is one of the best ways for new parents to receive support. Meal delivery services take cooking off the new family’s plate, while ensuring they receive wholesome, delicious, ready-made meals.',
        cost: 20,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/ellieelien-E87UW-n4X1Q-unsplash%201@2x.png'
    },
    {
        id: 'c95eb6f4-4a45-4e89-bd28-60c35c856393',
        name: 'Meal Delivery for the New Mom - Meals for One Week',
        text: 'Receiving the gift of nutritious food is one of the best ways for new parents to receive support. Meal delivery services take cooking off the new family’s plate, while ensuring they receive wholesome, delicious, ready-made meals.',
        cost: 150,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/brooke-lark.png'
    },
    {
        id: '92767df1-af73-42e6-bebe-18fad4c6ed9d',
        name: 'Meal Delivery for Two',
        text: 'Receiving the gift of nutritious food is one of the best ways for new parents to receive support. Meal delivery services take cooking off the new family’s plate, while ensuring they receive wholesome, delicious, ready-made meals.',
        cost: 40,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/christiann-koepke-386013-unsplash%201@2x.png'
    },
    {
        id: 'c86d2417-a6e2-4dfc-8f8f-f4817e641880',
        name: 'Meal Delivery for Two - Meals for One Week',
        text: 'Receiving the gift of nutritious food is one of the best ways for new parents to receive support. Meal delivery services take cooking off the new family’s plate, while ensuring they receive wholesome, delicious, ready-made meals.',
        cost: 300,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/eiliv-sonas-aceron-w0JzqJZYX_E-unsplash%201@2x.png'
    },
    {
        id: '37349315-7775-4c94-a33b-89cf04562c57',
        name: 'Mercier Therapy Session',
        text: 'Mercier Therapy is a safe and gentle external pelvic visceral manipulation technique designed to restore the health and function of the pelvis by addressing adhesions, scar tissue, and decreased mobility of the pelvic and reproductive structures. This therapy has proven to be beneficial for postpartum women as well as those with fertility challenges.',
        cost: 500,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp9e101f12tRQ300_Medium_4589023%201@2x.png'
    },
    {
        id: 'db6b74de-a4f3-4970-b595-85c057204cea',
        name: 'Mommy and Me Group',
        text: 'Community makes motherhood much more fun. Virtual and in-person meetings with other new parents can provide much needed social support, education, and emotional healing throughout the different stages of matrescence. Mom groups are a communal way to connect with other parents, bond with baby, and learn from the experts—some moms even make lifelong friendships that began in their very first baby group.',
        cost: 300,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-vlada-karpovich-4617294%201@2x.png'
    },
    {
        id: 'a1f989bb-b914-40ec-9e44-9828f13d54ad',
        name: 'Nanny - One Week',
        text: 'Parenting was never meant to be done alone. Babysitters, nannies, mother’s helpers, and daycare provide additional care, support, and safety for families. Parents juggle a lot these days—access to quality childcare is often what makes it all possible.',
        cost: 600,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/taisiia-shestopal-lwHYpOauDjo-unsplash%201@2x.png'
    },
    {
        id: 'a742cc92-7212-44d1-9078-693c691bc5ec',
        name: 'Newborn Care Specialist - One Overnight Session',
        text: 'Newborn Care Specialists (NCSs) help new parents learn new tasks like swaddling, breastfeeding, infant sleep, bathing, and other aspects of newborn care. Through specialized training and caring support, they provide education and an extra set of hands during the transition to parenthood. Many new families utilize NCSs in the overnight hours, so the new parents can rest assured their baby is looked after while they get much-needed sleep.',
        cost: 300,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/1746277%201@2x.png'
    },
    {
        id: 'b66347fd-1b5f-4225-acae-a6530392f6d3',
        name: 'Newborn Care Specialist - One Week Overnight Sessions',
        text: 'Newborn Care Specialists (NCSs) help new parents learn new tasks like swaddling, breastfeeding, infant sleep, bathing, and other aspects of newborn care. Through specialized training and caring support, they provide education and an extra set of hands during the transition to parenthood. Many new families utilize NCSs in the overnight hours, so the new parents can rest assured their baby is looked after while they get much-needed sleep.',
        cost: 2000,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/LittleHoneyMoney.png'
    },
    {
        id: '6b087ae6-8a71-46e2-9f29-ecf04bf3fde1',
        name: 'Nutrition Consultant - Fertility',
        text: 'Whether it’s choosing a prenatal vitamin, ensuring you’re taking in enough iron, eating nutrient-dense foods for recovery, or keeping up with the caloric demands of breastfeeding, a mother’s food intake is an important part of her wellness journey. A registered dietitian (R.D.) provides advice, support, and guidance around nutrition as needs change.',
        cost: 175,
        categories: ['Planning'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/lisa-hobbs-496188-unsplash%201@2x.png'
    },
    {
        id: '7d95de43-91c0-4329-a832-3b04e787fa7b',
        name: 'Nutrition Consultant - Postpartum',
        text: 'Whether it’s choosing a prenatal vitamin, ensuring you’re taking in enough iron, eating nutrient-dense foods for recovery, or keeping up with the caloric demands of breastfeeding, a mother’s food intake is an important part of her wellness journey. A registered dietitian (R.D.) provides advice, support, and guidance around nutrition as needs change.',
        cost: 175,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/calum-lewis-391372-unsplash%201@2x.png'
    },
    {
        id: 'c3312bea-3e24-4a2a-bfcb-bc1597809539',
        name: 'Nutrition Consultant - Prenatal',
        text: 'Whether it’s choosing a prenatal vitamin, ensuring you’re taking in enough iron, eating nutrient-dense foods for recovery, or keeping up with the caloric demands of breastfeeding, a mother’s food intake is an important part of her wellness journey. A registered dietitian (R.D.) provides advice, support, and guidance around nutrition as needs change.',
        cost: 175,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/american-heritage-chocolate-vxRg79EqLZU-unsplash%201@2x.png'
    },
    {
        id: '7e2824d6-6b92-4dd1-8338-5d7a90fbe534',
        name: 'On-Demand Maternal Support Service - One Month',
        text: 'Technology makes it easy for new moms to have support every step of the way. There are a number of maternal support resources and apps available to give expectant and new moms answers to all of their questions from conception to new motherhood and beyond. Monthly membership benefits often include expert advice and customized support.',
        cost: 30,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/728379%201@2x.png'
    },
    {
        id: 'c4023c59-958e-4a65-82be-b4c206eb7f46',
        name: 'Parenting Coaching Session',
        text: 'Parenthood brings a major shift, and with it comes brand new experiences at every turn—but with coaching along the way, so much of it can come as second nature, giving parents the confidence to make decisions for their growing family—including support for introducing siblings to a new baby and easing the transition for the entire family.',
        cost: 150,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/sai-de-silva-cykFL7IQCTk-unsplash%201@2x.png'
    },
    {
        id: 'fd15539d-d2ab-4a71-ad0c-f34e2b9d0621',
        name: 'Pelvic Floor Physical Therapy Session',
        text: 'Pelvic floor physical therapy is important during the postpartum period as it involves the strengthening and rehabilitation of muscles that support the pelvic organs helps prepare for and recover from childbirth. This work can also involve rehabilitating the core muscles and recovery from a vaginal or cesarean delivery.',
        cost: 175,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-shvets-production-6975249%201@2x.png'
    },
    {
        id: '2de3608b-be04-4f16-a9fe-56779cfd9e9a',
        name: 'Perinatal Psychotherapy Session',
        text: 'Mental health professionals who specialize in perinatal mood and anxiety disorders (PMADs), such as postpartum anxiety and postpartum depression, help mothers navigate a variety of common and treatable mental health conditions that can occur throughout the reproductive journey. Many women choose lean on mental health providers for support, even when they are considered at low risk of developing PMADs.',
        cost: 150,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/elizabeth-lies-486745-unsplash%201@2x.png'
    },
    {
        id: '2ad2e219-0d86-40cc-a899-d66f9f905cfe',
        name: 'Perinatal Psychotherapy - One Month Weekly Series',
        text: 'Mental health professionals who specialize in perinatal mood and anxiety disorders (PMADs), such as postpartum anxiety and postpartum depression, help mothers navigate a variety of common and treatable mental health conditions that can occur throughout the reproductive journey. Many women choose lean on mental health providers for support, even when they are considered at low risk of developing PMADs.',
        cost: 600,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/kellie-enge-fEyDBj34dws-unsplash%201@2x.png'
    },
    {
        id: '36793d3c-53b9-448d-bf31-f4b8969ecfb4',
        name: 'Placenta Encapsulation Service',
        text: 'Placenta encapsulation is a process where the placenta is turned into a powder form and put into pill capsules to be ingested by the postpartum mother.Many believe in the benefits of placenta encapsulation to include: a decrease in postpartum mood disorders, increased production of oxytocin, a decrease in stress hormones, restoration of iron levels following bleeding after birth, and an increased milk supply.',
        cost: 250,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/ramiro-mendes-371655-unsplash%201@2x.png'
    },
    {
        id: 'd80e313e-4a58-4c0c-b318-45f89a7a0025',
        name: 'Postpartum Doula - One Week Support',
        text: 'Postpartum doulas provide families information and support on emotional and physical recovery from childbirth, infant feeding and soothing, and coping skills for new parents. As doulas support the entire family, one might also help with light housework, fixing meals, and helping to care for siblings.',
        cost: 2000,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/bathroom.png'
    },
    {
        id: '7ed9abb3-01c8-480c-85bf-c01be4c7c78e',
        name: 'Postpartum Massage',
        text: 'No one deserves a massage more than an expectant or new mom. Fertility, prenatal and postpartum bodywork has a number of benefits including relaxation, stress and pain relief, reduced swelling, increased blood flow, immunity, and better sleep.',
        cost: 150,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/alex-loup.png'
    },
    {
        id: '37ef2b1d-f10b-47a3-a680-ea0151618303',
        name: 'Prenatal Education Class',
        text: 'In-person and virtual childbirth and parenting classes provide education and support around trying to conceive, labor and delivery, preparing for postpartum, caring for a newborn, and more—including the CPR and safety training that are so essential to infant care.',
        cost: 100,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/melanie-brown-59Zw6yv3kbQ-unsplash%201@2x.png'
    },
    {
        id: 'bc182baa-ff6d-455d-8f21-6c7fe4073bc7',
        name: 'Prenatal Massage',
        text: 'No one deserves a massage more than an expectant or new mom. Fertility, prenatal and postpartum bodywork has a number of benefits including relaxation, stress and pain relief, reduced swelling, increased blood flow, immunity, and better sleep.',
        cost: 150,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/mediamodifier.png'
    },
    {
        id: 'cbd038ca-10bc-47e3-a469-fda7e98fe96d',
        name: 'Prenatal Yoga Class',
        text: 'Prenatal yoga is a common way for expectant moms to stay active during pregnancy. It is a gentle and low impact practice, that offers physical and mental benefits like improved sleep, increased muscle endurance, reduced stress and anxiety, back pain relief, and more.',
        cost: 45,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/kaylee-garrett-GaprWyIw66o-unsplash%201@2x.png'
    },
    {
        id: '420ab3f4-1626-47b8-b2a7-c4fe0efbdb8e',
        name: 'Prenatal Pilates Class',
        text: 'Pilates is a non-impact workout that increases flexibility, strength and muscle tone, and is safe to practice with a prenatal focus. Because it focuses on your core, practicing Pilates on a regular basis can improve posture, alleviate backaches, and help with labor and delivery.',
        cost: 45,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/the-nix-company-F2w2YAZFdLI-unsplash%201@2x.png'
    },
    {
        id: 'a46a2ce9-fe62-474f-94a9-71a721e7b6ec',
        name: 'Prenatal & Birth Doula - One Month Support',
        text: 'Many women choose to have a doula present to coach and advocate for them during at-home and hospital births. Doulas are trained professionals who provide continuous physical, emotional, and informational support to a mother before, during and, after childbirth. Doulas provide invaluable comfort to all family members, in addition to the mother. Research has shown that having a doula’s support during—and leading up to—childbirth can result in better outcomes for both mom and baby.',
        cost: 500,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp273a1e32QNQ300_Medium_2669831@2x.png'
    },
    {
        id: '6dc7eed3-b9f3-403f-97c4-4b085206010b',
        name: 'Prenatal & Birth Doula - One Month Virtual Support',
        text: 'Doulas are trained professionals who provide continuous physical, emotional, and informational support to a mother before, during and, after childbirth. Doulas provide invaluable comfort to all family members, in addition to the mother. Research has shown that having a doula’s support during—and leading up to—childbirth can result in better outcomes for both mom and baby.',
        cost: 200,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp273a1e32QNQ300_Medium_2669859@2x.png'
    },
    {
        id: 'd0f04475-3fb2-478a-88bd-21af9d9165d2',
        name: 'Professional Safety Car Seat Installation & Training',
        text: 'New parents often wonder if they\'re doing it right—especially when it comes to their children\'s safety. But with the help of car seat installation professionals, parents can rest assured their car seats are installed exactly as they should be, without worry or stress.',
        cost: 100,
        categories: ['Pregnancy', 'Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy_txp9ef21c03kNQ300_Medium_3044721%201@2x.png'
    },
    {
        id: '5c92546a-1213-4b04-a4dc-e4b9d6113907',
        name: 'Reflexology Session',
        text: 'Reflexology is the application of pressure to points on the feet or hands. When performed by a registered massage therapist or reflexologist with training in prenatal massage, it is safe during pregnancy and can be very helpful to relieve common discomforts like morning sickness, heartburn, and body aches.',
        cost: 125,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/Stocksy.png'
    },
    {
        id: '2afc3f22-d67e-4f9a-a9c9-1549d0e19462',
        name: 'Reiki Session',
        text: 'Reiki is an energy healing technique that promotes relaxation, reduces stress and anxiety through gentle touch. Reiki practitioners use their hands to deliver energy to the body, improving the flow and balance of energy to support healing. While it is used during all phases of new motherhood, women having difficulty conceiving, or experiencing infertility, can especially benefit from Reiki as it helps to remove energetic blocks that might be a contributor in infertility.',
        cost: 175,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/1033928%201@2x.png'
    },
    {
        id: 'de18061b-d0f0-49e3-adee-2016db818292',
        name: 'Sleep Consultant',
        text: 'Many families seek the support of a sleep consultant to help them understand their baby\'s sleep schedule and how to nurture their baby\'s sleep habits—as well as their own. A sleep consultant\'s goal is for everyone in the family to be sleeping soundly.',
        cost: 500,
        categories: ['Postpartum', 'Baby'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/sincerely-media-9nhxEa3PK30-unsplash%201@2x.png'
    },
    {
        id: 'd5fba183-7a50-44d6-a426-201472c928ca',
        name: 'Sound Healing Session',
        text: 'Sound healing uses aspects of music and sound bowls to improve physical and emotional health and well-being. Sound healing sessions can be performed in individual or communal settings. The harmonizing effect produced by the sound creates a soothing effect throughout the entire body. Because it is important to maintain low stress levels during pregnancy, expectant mothers and their babies are known to benefit greatly from this pratice.',
        cost: 50,
        categories: ['Planning', 'Pregnancy', 'Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/content-pixie-NxpUAW8ueMw-unsplash%201@2x.png'
    },
    {
        id: '2f78ad1c-fccc-4d15-91cc-3d5767e61062',
        name: 'Virtual New Mom Support Group',
        text: 'Community makes motherhood much more fun. Virtual and in-person meetings with other new parents can provide much needed social support, education, and emotional healing throughout the different stages of matrescence. Mom groups are a communal way to connect with other parents, bond with baby, and learn from the experts—some moms even make lifelong friendships that began in their very first baby group.',
        cost: 400,
        categories: ['Postpartum'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/jessica-felicio-lH973Qz0Iy4-unsplash%202@2x.png'
    },
    {
        id: 'f636fab0-b049-464f-a48b-1b5ec0a58c2a',
        name: 'Virtual Pregnancy Support Group',
        text: 'Community makes motherhood much more fun. Virtual and in-person meetings with other new parents can provide much needed social support, education, and emotional healing throughout the different stages of matrescence. Mom groups are a communal way to connect with other parents, bond with baby, and learn from the experts—some moms even make lifelong friendships that began in their very first baby group.',
        cost: 400,
        categories: ['Pregnancy'],
        type: 'service',
        image: 'https://little-honey-money.imgix.net/registry/pexels-andrea-piacquadio-3830752%201@2x.png'
    },

]

export const all = [
    ...funds,
    ...serviceItems
]

export default [
    {
        id: 'services',
        title: 'SERVICE ITEMS',
        image: img10,
        items: serviceItems
    },
    {
        id: 'funds',
        title: 'FUNDS',
        image: img10,
        items: funds
    }
]
