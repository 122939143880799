export const fundsMom = [
    {
        id: 'dfa791d9-6617-49d4-827d-c025f56c388f',
        name: 'DOULA',
        description:'The ultimate support before, during, and after pregnancy'
    },
    {
        id: '256ecd14-5b66-428f-8f0c-e08a97dd0f41',
        name: 'MASSAGE',
        description:'Boost immune function, mental health, stress and pain relief, and more'
    },
    {
        id: 'f7fea75c-1be3-408e-87ed-13290d598a0f',
        name: 'FERTILITY',
        description:'Understanding the multi-layered and unique journey to motherhood'
    },
]

export const fundsBaby = [
    {
        id: '20be24ce-5809-4cc0-b222-696b2a53e6dd',
        name: 'CHILDCARE',
        description:'How moms make it all work'
    },
    {
        id: '14b820cd-c350-42d2-8538-07fb78a367d2',
        name: 'ACUPRESSURE',
        description:'Safe, tender techniques to ease common infant discomforts'
    },
    {
        id: 'e6e0d9e4-a437-493b-ab27-f7c5eaed6228',
        name: 'NEWBORN CARE SPECIALIST',
        description:'Learn about baby care straight from the pros'
    },
]
