import * as types from './types'
import services from '../services'
import { DataStore } from '@aws-amplify/datastore'
import { Notification } from '../src/models'

export const updateRegistryForm = (payload) => ({ type: types.UPDATE_REGISTRY_FORM, payload })

export const resetSignupForm = () => ({ type: types.RESET_SIGNUP_FORM })

export const updateCart = (payload) => ({ type: types.UPDATE_CART, payload })

export const resetCart = () => ({ type: types.RESET_CART })

export const updateUser = (payload) => ({ type: types.UPDATE_USER, payload })

export const updateAuthUser = (payload) => ({ type: types.UPDATE_AUTH_USER, payload })

export const updateRegistry = (payload) => ({ type: types.UPDATE_REGISTRY, payload })

export const updateFunds = (payload) => ({ type: types.UPDATE_FUNDS, payload })

export const updatePosts = (payload) => ({ type: types.UPDATE_POSTS, payload })

export const updateNotifications = (payload) => ({ type: types.UPDATE_NOTIFICATIONS, payload })

export const startLoader = () => ({ type: types.START_LOADING })

export const stopLoader = () => ({ type: types.STOP_LOADING })

export const fetchUser = () => {
    return async dispatch => {
        try {
            const { data: user } = await services.api.user.getMyDetails()
            dispatch(updateUser(user))
        } catch (err) {
            console.log(err)
            // throw err
        }
    }
}

export const fetchRegistry = () => {
    return async dispatch => {
        try {
            const registry = await services.api.registry.getMy()
            dispatch(updateRegistry(registry))
            dispatch(fetchFunds(registry.registryId))
        } catch (err) {
            console.log(err)
            // throw err
        }
    }
}

export const fetchRegistryAndUser = (useId) => {
    return async dispatch => {
        try {
            const registry = await services.api.registry.getMy()
            const { user } = await services.api.registry.getByUserIdWithUser(useId)
            dispatch(updateRegistry(registry))
            dispatch(updateUser(user))
            dispatch(fetchFunds(registry.registryId))
        } catch (err) {
            console.log(err)
            // throw err
        }
    }
}

export const fetchFunds = (registryId) => {
    return async dispatch => {
        try {
            const { data: funds } = await services.api.fund.getByRegistryId(registryId)
            dispatch(updateFunds(funds))
        } catch (err) {
            console.log(err)
            // throw err
        }
    }
}

export const fetchAuthUser = () => {
    return async dispatch => {
        try {
            const user = await services.auth.currAuthUser()
            dispatch(updateAuthUser(user))
        } catch (err) {
            dispatch(updateAuthUser(null))
            dispatch(updateUser(null))
            dispatch(updateRegistry(null))
        }
    }
}

export const fetchPosts = () => {
    return async dispatch => {
        try {
            const posts = await services.api.journal.getAllPosts()
            const categories = await services.api.journal.getAllCategories()
            dispatch(updatePosts(posts.map(item => {
                const [category_id] = item.categories
                const category = categories.find(({id}) => id === category_id)
                return { id: item.id, title: item.title.rendered, link: item.link, category: category?.name, thumb: item._embedded['wp:featuredmedia'][0].source_url  }
            })))
        } catch (err) {
            throw err
        }
    }
}

export const fetchNotifications = () => {
    return async dispatch => {
        try {
            const authUser = await services.auth.currAuthUser()
            const notifications = await DataStore.query(Notification, model => model
            .user_id('eq', authUser.username)
            .or(model => model.is_read('eq', false)
            .is_important('eq', true)))
            dispatch(updateNotifications(notifications.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))))
        } catch (err) {
            throw err
        }
    }
}

export const submitNewsletterForm = (payload) => ({ type: types.NEWSLETTER_SUBMIT, payload })