import { API } from 'aws-amplify'
import auth from '../auth'
import errorHandler from './errorHandler'

const config = async () => {
    const cognitoUserSession = await auth.session()
    return { headers: { Authorization: cognitoUserSession.idToken.jwtToken } }
}

export default {
    // TODO: add stripe create session API
    contribute (queryData) {
        return API.post('Contributions', '/checkout/create', { body: queryData })
    },
    getWithFundId (fundId) {
        return API.get('Contributions', `/contributions/funds/${fundId}`)
    },
    async getWithRegistryId (queryData) {
        try {
            const configObj = await config()
            return await API.get('Contributions', `/contributions/registry/${queryData.registryId}?startDate=${queryData.startDate}&endDate=${queryData.endDate}`, { body: queryData, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    async getPaymentsWithRegistryId (registryId) {
        try {
            const configObj = await config()
            return await API.get('Contributions', `/payments/registry/${registryId}`, configObj)
        } catch (e) {
            errorHandler(e)
        }
    }
}
