/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://vss27divczd2nl6voln2gwcqfq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yyst7yewxfe5rpjp7y7gkdnc4q",
    "aws_cognito_identity_pool_id": "us-east-1:ee4e1657-508b-48be-8e8a-0b4fbb65b978",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Sk5zVHrvO",
    "aws_user_pools_web_client_id": "2abr92cqe2u51rktk7lnsj4ui1",
    "oauth": {
        "domain": "little-honey-money-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "littlehoneymoneymono7f0240784375423388eff13e20d-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
