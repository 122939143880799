import fetch from './fetch'

export default {
    getAll() {
        return fetch.get('https://podcast.littlehoneymoney.com/wp-json')
        .catch(e => console.log(e));
    },

    getAcf() {
        return fetch.get('https://podcast.littlehoneymoney.com/wp-json/wp/v2/pages/1170?_fields=acf&acf_format=standard')
        .catch(e => console.log(e));
    },

    async getPodcasts() {
        let exist = true;
        let records = [];
        let offset = 0;
        let page = 1;

        do {
            let response = await fetch.get(`https://podcast.littlehoneymoney.com/wp-json/wp/v2/podcast?per_page=10&page=${page}&offset=${offset}&_embed`)
            .catch(e => console.log(e));
            exist = response && response.length === 10;
            if (exist) {
                offset +=10;
                page++;
            }
            records = [...records, ...response];
        } while (exist);

        return records;
    },
}
