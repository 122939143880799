import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'

const DEFAULT_LOCALE = 'en-US'
const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'

export default {
    number: {
        currency (number, locale = DEFAULT_LOCALE, currency = DEFAULT_CURRENCY) {
            return new Intl.NumberFormat(locale, {
                style: 'currency',
                currency,
                maximumFractionDigits: 2,
                minimumFractionDigits: 0
            }).format(number)
        }
    },
    date (date, finalFormat = DEFAULT_DATE_FORMAT, baseFormat) {

        const d = baseFormat ? parse(date, baseFormat, new Date()) : new Date(date)
        return isValid(d) ? format(d, finalFormat) : format(new Date(), finalFormat)
    }
}
