import { combineReducers } from 'redux'

import * as types from './types'

// INITIAL REGISTRY FORM STATE
const initialRegistryFormState = {
    education: '',
    breastfeeding: '',
    help: '',
    interests: []
}

// REGISTRY FORM REDUCER
const registryFormReducer = (state = initialRegistryFormState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_REGISTRY_FORM:
            return { ...state, ...payload }
        default:
            return state
    }
}

// INITIAL SIGNUP FORM STATE
const initialSignupState = {
    step: 1,

    // Step 1
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confPassword: '',
    phone: '',

    // Step 2
    date: '',
    gender: '',
    partners: '',
    experience: '',
    city: '',
    state: '',
    fees: '',
    agree: false
}

// SIGNUP FORM REDUCER
const signupReducer = (state = initialSignupState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_SIGNUP_FORM:
            return { ...state, ...payload }
        case types.RESET_SIGNUP_FORM:
            return initialSignupState
        default:
            return state
    }
}

// INITIAL SIGNUP FORM STATE
const initialCartState = { items: [] }

// SIGNUP FORM REDUCER
const cartReducer = (state = initialCartState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_CART:
            return { // or use (new Date()).toJSON()
                items: payload
            }
        case types.RESET_CART:
            return initialCartState
        default:
            return state
    }
}

// INITIAL USER STATE
const initialUserState = null

// SIGNUP FORM REDUCER
const userReducer = (state = initialUserState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_USER:
            return payload
        default:
            return state
    }
}

// INITIAL AUTH USER STATE
const initialAuthUserState = null

// SIGNUP FORM REDUCER
const authUserReducer = (state = initialAuthUserState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_AUTH_USER:
            return payload
        default:
            return state
    }
}

// INITIAL REGISTRY STATE
const initialRegistryState = null

// REGISTRY REDUCER
const registryReducer = (state = initialRegistryState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_REGISTRY:
            return payload
        default:
            return state
    }
}

// INITIAL REGISTRY STATE
const initialFundsState = []

// REGISTRY REDUCER
const fundsReducer = (state = initialFundsState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_FUNDS:
            return payload
        default:
            return state
    }
}

// INITIAL LOADER STATE
const initialLoaderState = false

// LOADER REDUCER
const loaderReducer = (state = initialLoaderState, { type }) => {
    switch (type) {
        case types.START_LOADING:
            return true
        case types.STOP_LOADING:
            return false
        default:
            return state
    }
}

// INITIAL LOADER STATE
const initialPostsState = { list: [] }

// LOADER REDUCER
const postsReducer = (state = initialPostsState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_POSTS:
            return {
                // loadedAt: moment(),  // or use (new Date()).toJSON()
                list: payload
            }
        default:
            return state
    }
}

// INITIAL NOTIFICATIONS STATE
const initialNotificationsState = []

// NOTIFICATIONS REDUCER
const notificationsReducer = (state = initialNotificationsState, { type, payload }) => {
    switch (type) {
        case types.UPDATE_NOTIFICATIONS:
            return payload
        default:
            return state
    }
}

// NEWSLETTER SUBMIT STATE
const initialNewsletterState = false

// NEWSLETTER SUBMIT REDUCER
const newsletterReducer = (state = initialNewsletterState, {type, payload}) => {
    switch(type) {
        case types.NEWSLETTER_SUBMIT:
            return payload
        default:
            return state
    }
}

// COMBINED REDUCERS
const reducers = {
    registryForm: registryFormReducer,
    signupForm: signupReducer,
    cart: cartReducer,
    user: userReducer,
    authUser: authUserReducer,
    registry: registryReducer,
    funds: fundsReducer,
    loader: loaderReducer,
    posts: postsReducer,
    notifications: notificationsReducer,
    newsletter: newsletterReducer
}

export default combineReducers(reducers)
