import { API } from 'aws-amplify'
import auth from '../auth'
import { getState } from '../../store'
import errorHandler from './errorHandler'

const config = async () => {
    const cognitoUserSession = await auth.session()
    return { headers: { Authorization: cognitoUserSession.idToken.jwtToken } }
}

const getMyId = async () => {
    const me = await auth.currAuthUser()
    return me.username
}

const isUserLoggedIn = async () => {
    try {
        const me = await auth.currAuthUser()
        return true
    } catch (e) {
        return false
    }

}

export default {
    // createFund :  (queryData) => await API.post("Funds","/",{body:queryData,...(await config())}),
    // getUserById : async (userId) => await API.get("Users",`/id/${userId}`),
    isLoggedIn: isUserLoggedIn,
    findRegistry (user) {
        return API.get('Users', `/search/${user}`)
    },
    async getMyDetails () {
        const id = await getMyId()
        return await API.get('Users', `/id/${id}`)
    },
    get (id) {
        return API.get('Users', `/id/${id}`)
    },
    async update (queryData) {
        try {
            const configObj = await config()
            return await API.put('Users', '/', { body: queryData, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    async setBankDetails (body) {
        try {
            const configObj = await config()
            return await API.put('Users', '/setbankdetails', { body, ...configObj })
        } catch (e) {
            errorHandler(e)
        }
    },
    async setExternalAccount (body) {
        try {
            const configObj = await config()
            const res = await API.put('Users', '/setexternalaccount', { body, ...configObj })
            const { registry } = getState()
            if (registry?.registryUrl) {
                fetch(`/registry/${registry.registryUrl}`)
            }
            return res
        } catch (e) {
            errorHandler(e)
        }
    },
    async removeBank () {
        try {
            const configObj = await config()
            const res = await API.del('Users', '/delete-bank-account', configObj)
            const { registry } = getState()
            if (registry?.registryUrl) {
                fetch(`/registry/${registry.registryUrl}`)
            }
            return res
        } catch (e) {
            errorHandler(e)
        }
    },
    subscribe (email) {
        return API.post('Users', '/subscribe', { body: { email } })
    },
    contact (body) {
        return API.post('Users', '/contact', { body })
    },
    async recaptchaVerify (token) {
        const { data } = await API.post('Users', '/recaptcha-verify', { body: { token } })
        return data
    },
    async getLinkToken (access_token = null) {
        const { user } = getState()
        const { data: { link_token } } = await API.post('Users', '/create-link-token', { body: { user, access_token } })
        return link_token
    }
}

