import router from 'next/router'
import auth from '../auth'

export default async error => {
    if (error.response?.status === 401) {
        await auth.signOut()
        router.push('/signin')
    } else {
        throw error
    }
}