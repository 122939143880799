import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'

const Header = dynamic(() => import('../components/Header'))
const Footer = dynamic(() => import('../components/Footer'))
const SupportWidget = dynamic(() => import('../components/SupportWidget'))
const DefaultLoader = dynamic(() => import('../components/UI/DefaultLoader'))

import services from '../services'

import { useStore } from '../store'
import { fetchAuthUser, fetchRegistryAndUser } from '../store/actions'

import '../assets/index.scss'

const App = ({ Component, pageProps }) => {
    const store = useStore(pageProps.initialReduxState)
    let { registry } = store.getState()
    const [loading, setLoading] = useState(false)
    const router = useRouter()
    const currentPath = router.pathname
    let errorCatcher;

    useEffect(() => {
       if (typeof window !== 'undefined') {
           document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
       }
    },[])

    useEffect(() => {
        if (router.pathname !== '/thanks-payment' && !window.location.search.includes('gclid')) {
            fetchInitialData()
        }
    }, [])

    useEffect(() => {
        guard()
    }, [Component])

    useEffect(() => {
        const fbScript = () => {
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '811051386943952');
        fbq('track', 'PageView');
        }
        document.addEventListener('DOMContentLoaded', fbScript())
        return () => document.removeEventListener('DOMContentLoaded', fbScript())
    }, [Component])

    const showNavs = () => {
        const fullPageUrls = ['/onboarding']
        return !fullPageUrls.some((path) => currentPath.startsWith(path))
    }

    const guard = async () => {
        services.ga.pageview(router.pathname)
        try {
            await store.dispatch(fetchAuthUser())
            if (!registry) registry = await services.api.registry.getMy()
            const questions = registry?.questions
            if (
                !router.pathname.startsWith('/onboarding')
                && (
                    !questions
                    || typeof questions.breastfeeding === 'undefined'
                    || typeof questions.education === 'undefined'
                    || typeof questions.help === 'undefined'
                )
            ) {
                router.push('/onboarding')
            }
        } catch (err) {
            errorCatcher = err
        }
    }

    const fetchInitialData = async () => {
        setLoading(true)
        try {
            await store.dispatch(fetchAuthUser())
            setLoading(false)
            await store.dispatch(fetchRegistryAndUser(store.getState().authUser.username))
        } catch (err) {
            errorCatcher = err
        } finally {
            setLoading(false)
        }
    }

    return <Provider store={store}>
        <Head>
            <meta charSet="utf-8"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/icon-16x16.png"/>
            <link rel="icon" type="image/png" sizes="24x24" href="/icon-24x24.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/icon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="64x64" href="/icon-64x64.png"/>
            <link rel="icon" type="image/png" sizes="96x96" href="/icon-96x96.png"/>
            <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
            <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta name="theme-color" content="#000000"/>
            <meta name="description" content="Little Honey Money"/>
            <meta name="facebook-domain-verification" content="4i6pvwmo4rj1ebrxvhdw26xfv0m79u" />
            <link rel="manifest" href="/manifest.json"/>
            <noscript>
                <img height="1" width="1" style={{display: 'none'}}
                     src="https://www.facebook.com/tr?id=811051386943952&ev=PageView&noscript=1"/>
            </noscript>
            <title>Little Honey Money</title>
        </Head>
        {/*Global site tag (gtag.js) - Google Analytics*/}
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} strategy="afterInteractive"/>
        <Script async strategy="afterInteractive">
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
            `}
        </Script>

        <Script id="gtm-script" async strategy="afterInteractive">
            {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KNT3SKLG');
            `}
        </Script>

        <noscript>
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-KNT3SKLG"
                style={{display:'none', visibility:'hidden'}}
                height="0"
                width="0"
            ></iframe>
        </noscript>

        <div className={`app ${currentPath === '/' ? 'regular' : currentPath.split('/').join('')}`}>
            {showNavs() && <Header/>}
            {loading && <DefaultLoader/>}
            <Component style={loading ? { display: 'none' } : {}} {...pageProps} />
        </div>

        {showNavs() && <Footer/>}
        <SupportWidget/>
        <Script>{`
            (function(){
                var s    = document.createElement('script');
                var h    = document.querySelector('head') || document.body;
                s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js';
                s.async  = true;
                s.onload = function(){
                    acsbJS.init({
                        statementLink    : '',
                        footerHtml       : '',
                        hideMobile       : false,
                        hideTrigger      : false,
                        disableBgProcess : false,
                        language         : 'en',
                        position         : 'left',
                        leadColor        : '#3e3c3c',
                        triggerColor     : '#000000',
                        triggerRadius    : '50%',
                        triggerPositionX : 'right',
                        triggerPositionY : 'bottom',
                        triggerIcon      : 'people',
                        triggerSize      : 'medium',
                        triggerOffsetX   : 20,
                        triggerOffsetY   : 70,
                        mobile           : {
                            triggerSize      : 'small',
                            triggerPositionX : 'right',
                            triggerPositionY : 'center',
                            triggerOffsetX   : 10,
                            triggerOffsetY   : 40,
                            triggerRadius    : '50%'
                        }
                    });
                };
                h.appendChild(s);
              })();
        `}</Script>
        <Script
            src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY}`}
            type="text/javascript"
            id="klaviyo-script"
            async
        />
    </Provider>
}

export default App
