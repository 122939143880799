// REDUX ACTION TYPES
export const UPDATE_REGISTRY_FORM = 'UPDATE_REGISTRY_FORM'

export const UPDATE_SIGNUP_FORM = 'UPDATE_SIGNUP_FORM'
export const RESET_SIGNUP_FORM = 'RESET_SIGNUP_FORM'

export const UPDATE_CART = 'UPDATE_CART'
export const RESET_CART = 'RESET_CART'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'

export const UPDATE_REGISTRY = 'UPDATE_REGISTRY'
export const UPDATE_FUNDS = 'UPDATE_FUNDS'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const UPDATE_POSTS = 'UPDATE_POSTS'

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const NEWSLETTER_SUBMIT = 'NEWSLETTER_SUBMIT'
