// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Notification, CommunitySlide, HomePage, FAQ, Dashboard, TextBg, JournalLink } = initSchema(schema);

export {
  Notification,
  CommunitySlide,
  HomePage,
  FAQ,
  Dashboard,
  TextBg,
  JournalLink
};