export default {
    async post (url, body, config = {}) {
        const res = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            ...config
        })
        return res.json()
    },
    async get (url, config = {}) {
        const res = await fetch(url, {
            method: 'GET',
            ...config
        })
        return res.json()
    }
}
