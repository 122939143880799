import { Auth } from 'aws-amplify'
import api from './api'

import { messages, letterConverter } from '../utils'
letterConverter();

export default {
    async signIn (data) {
        try {
            return await Auth.signIn(data.email, data.password)
        } catch (err) {
            const message = messages[err.message] || err.message
            throw Error(message)
        }
    },

    async signUp (data) {
        try {
            return await Auth.signUp({
                username: data.email,
                password: data.password,
                attributes: {
                    email: data.email,
                    given_name: data.firstname.trim(),
                    family_name: data.lastname.trim(),
                    phone_number: '+1' + data.phone.replace(/[^0-9]+/g, '')
                }
            })
        } catch (err) {
            const message = messages[err.message] || err.message
            throw Error(message)
        }
    },

    signOut () {
        return Auth.signOut()
    },

    async reset (email) {
        try {
            return await Auth.forgotPassword(email)
        } catch (err) {
            const message = messages[err.message] || err.message
            throw Error(message)
        }
    },

    async resetSubmit (data) {
        try {
            return await Auth.forgotPasswordSubmit(
                data.email,
                data.code,
                data.password
            )
        } catch (err) {
            const message = messages[err.message] || err.message
            throw Error(message)
        }
    },

    async session () {
        return await Auth.currentSession()
    },

    async putDetails (data) {
        const payload = {
            dueDate: data.date,
            gender: data.gender,
            partnersName: data.partners,
            experience: data.experience,
            city: data.city,
            state: data.state,
            how_you_hear: data.how_you_hear,
            fees: data.fees,
            registryUrl: `${data.firstname.latinize()} ${data.lastname.latinize()}`
            .replace(/ +(?= )/g, '')
            .trim()
            .replace(/\s+/g, '-')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        }
        await api.registry.store(payload)
    },

    async updateUserAttr ({ data }) {
        const user = await Auth.currentAuthenticatedUser()
        const res = await Auth.updateUserAttributes(user, {
            email: data.email,
            given_name: data.firstname,
            family_name: data.lastname,
            phone_number: '+1' + data.phone.replace(/[^0-9]+/g, '')
        })
        await api.user.update({
            firstName: data.firstname,
            lastName: data.lastname,
            email: data.email
        })
        return res
    },

    async changePassword (data) {
        const user = await Auth.currentAuthenticatedUser()
        return await Auth.changePassword(
            user,
            data.oldPassword,
            data.newPassword
        )
    },

    async currAuthUser () {
        return await Auth.currentAuthenticatedUser()
    },

    async getIdToken () {
        const { idToken } = await Auth.currentSession()
        return idToken

        // const cognitoUser = await this.currAuthUser()
        // console.log(cognitoUser)
        // if (cognitoUser != null) {
        //     cognitoUser.getSession((err, session) => {
        //         if (err) {
        //             console.log(err);
        //             return 0
        //         } else if (!session.isValid()) {
        //             console.log("Invalid session.");
        //             return 0
        //         } else {
        //             return session.getIdToken().getJwtToken();
        //         }
        //     });
        // }
    },

    async currentUser () {
        return await Auth.currentUserInfo()
    }
}
