import { Amplify } from 'aws-amplify'

import awsExports from '../../src/aws-exports'

import fund from './fund'
import registry from './registry'
import user from './user'
import contributor from './contributor'
import journal from './journal'
import plaid from './plaid'
import fetch from './fetch'
import notifications from './notifications'
import stage from "./stage";
import category from "./category";
import location from "./location";
import listing from "./listing";
import podcast from "./podcast";

// import fetch from './fetch'

Amplify.configure({
    ...awsExports,
    ssr: true,
    API: {
        endpoints: [
            {
                name: 'Registry',
                endpoint: process.env.NEXT_PUBLIC_REGISTRY_URL
            },
            {
                name: 'Funds',
                endpoint: process.env.NEXT_PUBLIC_FUND_URL
            },
            {
                name: 'Users',
                endpoint: process.env.NEXT_PUBLIC_USERS_URL
            },
            {
                name: 'Contributions',
                endpoint: process.env.NEXT_PUBLIC_CONTRIBUTIONS_URL
            },
            {
                name: 'Payments',
                endpoint: process.env.NEXT_PUBLIC_PAYMENTS_URL
            },
            {
                name: 'Stage',
                endpoint: process.env.NEXT_PUBLIC_STAGE_API_URL
            },
            {
                name: 'Category',
                endpoint: process.env.NEXT_PUBLIC_CATEGORY_API_URL
            },
            {
                name: 'Location',
                endpoint: process.env.NEXT_PUBLIC_LOCATION_API_URL
            },
            {
                name: 'Listing',
                endpoint: process.env.NEXT_PUBLIC_LISTING_API_URL
            }
        ]
    }
})

export default {
    fund,
    registry,
    user,
    contributor,
    journal,
    plaid,
    notifications,
    stage,
    category,
    location,
    listing,
    podcast,
    recaptchaVerify (token) {
        return fetch.post('/api/recaptcha-verify', { token })
    }
}
